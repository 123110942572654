@tailwind base;
@tailwind components;
@tailwind utilities;

@import "@mci/product-menu/dist/esm/index.css";
@import "@mci/cross-platform-components/dist/index.min.css";

html {
  font-size: 16px;
  scroll-behavior: smooth;
}

@font-face {
  font-family: "Proxima Nova";
  src:
    url("https://cdn.morningconsultintelligence.com/MCI-1.1/fonts/PN-Regular.woff2")
      format("woff2"),
    url("https://cdn.morningconsultintelligence.com/MCI-1.1/fonts/PN-Regular.woff")
      format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Proxima Nova";
  src:
    url("https://cdn.morningconsultintelligence.com/MCI-1.1/fonts/PN-Semibold.woff2")
      format("woff2"),
    url("https://cdn.morningconsultintelligence.com/MCI-1.1/fonts/PN-Semibold.woff")
      format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Proxima Nova";
  src:
    url("https://cdn.morningconsultintelligence.com/MCI-1.1/fonts/PN-Bold.woff2")
      format("woff2"),
    url("https://cdn.morningconsultintelligence.com/MCI-1.1/fonts/PN-Bold.woff")
      format("woff");
  font-weight: 700;
  font-style: normal;
}

/* Apply endil focus styles to any one-off button/anchor tag usage (e.g. next/link)*/
@layer components {
  a,
  button {
    @apply focus-visible:outline-brand;
    @apply focus-visible:outline-offset-2;
  }

  .turnstile-widget-container iframe {
    @apply rounded-3;
  }
}

/* Important is required in background to overwrite CSS coming from Everviz */
div[id^="everviz-layout-"] .carousel-container .dropdown-container select {
  appearance: none;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' id='Layer_1' x='0px' y='0px' viewBox='0 0 500 500' enable-background='new 0 0 500 500' xml:space='preserve'%3E%3Cg%3E%3Cg%3E%3Cline fill='none' stroke='%23000000' stroke-width='20' stroke-linecap='square' stroke-miterlimit='10' x1='169.5' y1='209.8' x2='250' y2='290.2'/%3E%3Cline fill='none' stroke='%23000000' stroke-width='20' stroke-linecap='square' stroke-miterlimit='10' x1='330.5' y1='209.8' x2='250' y2='290.2'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")
    no-repeat transparent right center !important;
}
.tp-backdrop.tp-active {
  opacity: 0.5 !important;
}

.line-clamp-3 > p {
  display: inline;
}
.line-clamp-2 > p {
  display: inline;
}

.EverViz div[id^="everviz-layout-"] .carousel-container .tabs-container {
  display: block;
}
